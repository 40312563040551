<template>
    <v-container fluid>
        <div class="tw-grid tw-gap-4 md:tw-grid-cols-2">
            <v-card>
                <app-table
                    v-model="selectedInvoices"
                    url="invoices/outstanding"
                    :columns="columns"
                    height="calc(100vh - 150px)"
                    show-select
                    hide-default-footer
                    caption="Outstanding Invoices"
                    @ajaxSuccess="handleTableAjaxSuccess"
                    :limit="100"
                    :searchable="false"
                    ref="table"
                >
                    <template v-slot:item.status_for_human="{ item }">
                        <v-chip>{{ item.status_for_human }}</v-chip>
                    </template>
                </app-table>
            </v-card>
            <div>
                <v-card>
                    <v-card-title>Make Payment</v-card-title>
                    <v-card-text>
                        <v-text-field
                            label="Amount"
                            v-model="totalAmount"
                            ref="totalAmount"
                            v-currency
                        />
                        <div>
                            <div>Pay with your credit card via Stripe.</div>
                            <v-radio-group
                                v-model="form.credit_card_ref"
                                :error="form.errors.has('credit_card_ref')"
                                :error-messages="form.errors.get('credit_card_ref')"
                                @change="form.errors.clear('credit_card_ref')"
                                class="mt-0"
                                :disabled="initializing"
                            >
                                <div
                                    v-for="card in cards"
                                    :key="card.id"
                                >
                                    <v-radio
                                        :label="card.name"
                                        :value="card.ref"
                                        class="my-2"
                                    ></v-radio>
                                    <v-divider/>
                                </div>
                                <v-radio
                                    label="Use a new payment method."
                                    value="new"
                                    class="my-2"
                                ></v-radio>
                            </v-radio-group>
                        </div>
                        <div v-if="form.credit_card_ref === 'new'">
                            <v-text-field
                                type="text"
                                label="Card number"
                                autofocus
                                v-model="form.credit_card_number"
                                :error="form.errors.has('credit_card_number')"
                                :error-messages="form.errors.get('credit_card_number')"
                                @keydown="form.errors.clear('credit_card_number')"
                                required
                                ref="number"
                            />
                            <div class="tw-grid tw-gap-3 tw-grid-cols-2">
                                <v-text-field
                                    type="text"
                                    label="Expiration date"
                                    v-model="form.credit_card_expiry"
                                    :error="form.errors.has('credit_card_exp_month')"
                                    :error-messages="form.errors.get('credit_card_exp_month')"
                                    @keydown="form.errors.clear('credit_card_exp_month')"
                                    required
                                    ref="expiry"
                                />
                                <v-text-field
                                    type="text"
                                    label="CVC"
                                    v-model="form.credit_card_cvc"
                                    :error="form.errors.has('credit_card_cvc')"
                                    :error-messages="form.errors.get('credit_card_cvc')"
                                    @keydown="form.errors.clear('credit_card_cvc')"
                                    required
                                    ref="cvc"
                                />
                            </div>
                            <v-text-field
                                type="text"
                                label="Card name"
                                v-model="form.credit_card_name"
                                :error="form.errors.has('credit_card_name')"
                                :error-messages="form.errors.get('credit_card_name')"
                                @keydown="form.errors.clear('credit_card_name')"
                                required
                            />
                            <v-checkbox
                                v-model="form.save"
                                label="Save payment information to my account for future payments."
                                class="mt-0"
                            ></v-checkbox>
                            <v-checkbox
                                v-if="me.default_cc && form.save"
                                v-model="form.default"
                                label="Set as default"
                                class="mt-0"
                            ></v-checkbox>
                        </div>
                    </v-card-text>
                    <v-card-title>Receipt Recipients</v-card-title>
                    <v-card-text>
                        <v-combobox
                            v-model="form.recipients"
                            :items="recipients"
                            label="Recipients (Required)"
                            :error-messages="form.errors.get('recipients')"
                            @input="form.errors.clear('recipients')"
                            chips
                            multiple
                            deletable-chips
                            :disabled="initializing"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            block
                            color="primary"
                            @click="pay"
                            :loading="processing"
                            :disabled="$refs.totalAmount && ($ci.getValue($refs.totalAmount) === 0 || $ci.getValue($refs.totalAmount) === null)"
                        >Pay {{ totalAmount }}</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </div>
        <v-dialog
            v-model="showStripeError"
            persistent
            max-width="400"
            scrollable
        >
            <v-card>
                <v-card-title class="tw-flex tw-items-center">
                    <v-icon color="red">fal fa-exclamation-triangle</v-icon>
                    <div class="tw-ml-2">Oops! something went wrong</div>
                </v-card-title>
                <v-card-text>
                    <div>{{ stripeError.message }} <a @click="showStripeErrorDetails = true">Details</a></div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="showStripeError = false"
                    >Close</v-btn>
                </v-card-actions>
            </v-card>
            <v-dialog
                v-model="showStripeErrorDetails"
                persistent
                max-width="600"
                scrollable
            >
                <v-card>
                    <v-card-title class="tw-flex tw-items-center">
                        <div class="tw-ml-2">Error Details</div>
                    </v-card-title>
                    <v-card-text>
                        <div
                            class="tw-whitespace-pre-wrap tw-bg-gray-100 tw-p-4"
                        >{{ JSON.stringify(stripeError, null, 4) }}</div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="showStripeErrorDetails = false"
                        >Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-dialog>
        <v-dialog
            v-model="showSuccessModal"
            persistent
            max-width="500"
            scrollable
        >
            <v-card>
                <v-card-title>
                    <div class="tw-w-full">
                        <div class="tw-text-center tw-py-4">
                            <v-icon color="green" x-large>fas fa-check-circle</v-icon>
                        </div>
                        <div class="tw-text-center">Payment successful</div>
                        <div class="tw-text-center tw-text-base tw-font-normal">A payment receipt was sent to {{ form.recipients.join(', ') }}</div>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <th>Amount</th>
                                    <td>{{ successResponse.amount_received_for_human }}</td>
                                </tr>
                                <tr>
                                    <th>Transaction ID</th>
                                    <td>{{ successResponse.transaction_id }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="showSuccessModal = false"
                    >Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
    import Payment from 'payment';
    import Http from '@/utils/httpClient';
    import Table from '@/components/Table.vue';
    import Form from '@/utils/form';
    import { mapGetters } from 'vuex';

    export default {
        components: {
            appTable: Table,
        },
        computed: {
            ...mapGetters(['me']),
        },
        data() {
            return {
                showStripeError: false,
                showStripeErrorDetails: false,
                showSuccessModal: false,
                successResponse: {},
                stripeError: {},
                processing: false,
                initializing: false,
                selectedInvoices: [],
                recipients: [],
                cards: [],
                totalAmount: 0,
                form: new Form({
                    amount: null,
                    invoice_ids: null,
                    credit_card_ref: null,
                    save: false,
                    default: false,
                    credit_card_name: null,
                    credit_card_number: null,
                    credit_card_exp_month: null,
                    credit_card_exp_year: null,
                    credit_card_cvc: null,
                    recipients: [],
                    credit_card_expiry: null
                }),
                columns: [
                    {
                        text: 'ID',
                        value: 'label',
                        sortable: false,
                        width: 130,
                    },
                    {
                        text: 'Date',
                        value: 'due_date_for_human',
                        sortable: false,
                        width: 130,
                    },
                    {
                        text: 'Balance',
                        value: 'balance_for_human',
                        sortable: false,
                        width: 130,
                    },
                ],
            }
        },
        watch: {
            selectedInvoices(items) {
                let totalAmount = 0;

                if (items.length) {
                    totalAmount = items.map((item) => parseFloat(item.balance))
                                       .reduce((total, value) => total + value);
                }

                this.$ci.setValue(this.$refs.totalAmount, totalAmount);
            },
            form: {
                handler(newForm) {
                    if (newForm.credit_card_ref === 'new') {
                        this.$nextTick(() => {
                            Payment.formatCardNumber(this.$refs.number.$el.querySelector('input'), 16);
                            Payment.formatCardExpiry(this.$refs.expiry.$el.querySelector('input'));
                            Payment.formatCardCVC(this.$refs.cvc.$el.querySelector('input'));
                        })

                        if (this.form.credit_card_expiry) {
                            const expiry = this.form.credit_card_expiry.split(' / ');

                            this.form.set({
                                credit_card_exp_month: expiry[0],
                                credit_card_exp_year: expiry[1],
                            });
                        }
                    }
                },
                deep: true
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            handleTableAjaxSuccess(data) {
                this.selectedInvoices = [];
                this.selectedInvoices = data.data;
            },
            init() {
                this.initializing = true;
                Http.get('payments/create', {
                    params: {
                        query: {
                            credit_card: ['id', 'name', 'ref', 'is_default'],
                            contact: ['email']
                        }
                    }
                })
                .then(({ data }) => {
                    this.initializing = false;
                    this.cards = data.cards;
                    this.recipients = data.recipients.map((recipient) => recipient.email);
                    this.form.recipients = this.recipients;

                    if (this.cards.length > 0) {
                        const defaultCard = this.cards.find((card) => card.is_default === true);

                        if (defaultCard) {
                            this.form.set({
                                credit_card_ref: defaultCard.ref,
                            });
                        } else {
                            this.form.set({
                                credit_card_ref: this.cards[0].ref,
                            });
                        }
                    } else {
                        this.form.set({
                            credit_card_ref: 'new',
                        });
                    }
                })
                .catch(() => {
                    this.initializing = false;
                })
            },
            pay() {
                setTimeout(() => {
                    this.processing = true;
                    this.form.errors.clear();
                    this.form.set({
                        invoice_ids: this.selectedInvoices.map((invoice) => invoice.id),
                        amount: this.$ci.getValue(this.$refs.totalAmount),
                    });
                    Http.post('payments', this.form.payload())
                        .then(({ data }) => {
                            this.processing = false;
                            this.$refs.table.init();
                            this.showSuccessModal = true;
                            this.successResponse = data;
                            this.form.reset();
                            this.form.recipients = this.recipients;
                            this.init();
                        })
                        .catch(({ response }) => {
                            this.processing = false;
                            this.form.errors.record(response.data);

                            if (this.form.errors.has('invoice_ids')) {
                                this.$root.$snackbar.open({
                                    text: 'Please select an outstanding invoice.',
                                    type: 'error',
                                    timeout: 0,
                                });
                            }

                            if (this.form.errors.has('Stripe\\Exception\\CardException')) {
                                this.showStripeError = true;
                                this.stripeError = this.form.errors.get('Stripe\\Exception\\CardException');
                            }

                            if (this.form.errors.has('Stripe\\Exception\\InvalidRequestException')) {
                                this.showStripeError = true;
                                this.stripeError = this.form.errors.get('Stripe\\Exception\\InvalidRequestException');
                            }
                        })
                }, 100)
            },
        }
    }
</script>
